import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { useForm } from 'react-hook-form';
import service from '../../services/service';
import validation from '../../utils/validation';
import { buildLocale } from '../../utils/buildLocale';
import Modal from 'react-modal';
import { MiniItemInput } from '../Forms/Components/ItemInput';
import bgZipImg from '../../assets/images/bg-zip_special.png';
import { actionsDelivery, selectorsDelivery } from '../../redux/delivery/deliveryReducer';

interface IData {
  zipCode: string;
}

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const ZipCodeModal = (props: IProps) => {
  const dispatch = useDispatch();
  const { isOpen, onClose } = props;
  const persistedZipCode = useSelector(selectorsDelivery.getZipCode) || '';
  const persistedIsInCourierArea = useSelector(selectorsDelivery.isInCourierArea) || '';
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSpecialDeliveryModal, setShowSpecialDeliveryModal] = useState(false);
  const isSubModalOpen = showErrorModal || showSpecialDeliveryModal;
  const [isFirstLoaded, setIsFirstLoaded] = useState(true);
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<IData>({
    reValidateMode: 'onSubmit',
    mode: 'onSubmit',
    defaultValues: {
      zipCode: persistedZipCode,
    },
  });

  useEffect(() => {
    if (isFirstLoaded) {
      return;
    }
    if (!persistedZipCode || persistedIsInCourierArea === null) {
      return;
    }
    const delayDebounceFn = setTimeout(() => {
      onClose();
      window.location.reload();
    }, 100);

    return () => {
      clearTimeout(delayDebounceFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persistedZipCode, persistedIsInCourierArea]);

  const onSubmit = (data: IData) => {
    setIsFirstLoaded(false);
    service
      .getZipCodeInfo(data.zipCode)
      .then(async (res) => {
        if (!res.success) {
          throw new Error('ERROR');
        }
        dispatch(actionsDelivery.setZipCode(data.zipCode));
        dispatch(actionsDelivery.setIsInCourierArea(res.data.isInCourierArea));

        if (!res.data.isInCourierArea) {
          setShowSpecialDeliveryModal(true);
          return;
        }
      })
      .catch((e) => {
        setShowErrorModal(true);
        console.error(e.message);
      });
  };

  const handleAcceptSpecialDelivery = () => {
    setShowSpecialDeliveryModal(false);
    onClose();
    window.location.reload();
  };

  return (
    <>
      {!isSubModalOpen && (
        <Modal isOpen={isOpen} className={`modal_zip-code`} overlayClassName="edit_overlay payment_overlay">
          <p className="zip-title">{buildLocale(currentTranslate, 'zipCodeModalTitle')}</p>
          <p className="zip-subtitle">{buildLocale(currentTranslate, 'zipCodeModalSubtitle')}</p>
          <form onSubmit={handleSubmit(onSubmit)} className="zip-form">
            <div className="form-item">
              <MiniItemInput
                className={'hide-arrows'}
                type={'number'}
                errors={errors}
                {...register('zipCode', validation.zipCode)}
              />
            </div>
            <button type="submit" className="btn">
              {buildLocale(currentTranslate, 'zipCodeModalButton')}
            </button>
          </form>
        </Modal>
      )}

      {showSpecialDeliveryModal && (
        <Modal
          isOpen={showSpecialDeliveryModal}
          onRequestClose={() => setShowSpecialDeliveryModal(false)}
          className={`modal_zip-code modal_zip-code_special`}
          overlayClassName="edit_overlay payment_overlay">
          <div className="zip-special">
            <div className="zip-special_left">
              <figure className="pic">
                <img src={bgZipImg} alt="" />
              </figure>
              <span className="font-semibold">{buildLocale(currentTranslate, 'zipCodeSpecialModalInfoBold')}</span>{' '}
              {buildLocale(currentTranslate, 'zipCodeSpecialModalInfo')}
            </div>
            <div className="zip-special_right">
              <p className="zip-title">{buildLocale(currentTranslate, 'zipCodeSpecialModalTitle')}</p>
              <p className="zip-subtitle">{buildLocale(currentTranslate, 'zipCodeSpecialModalSubtitle')}</p>
              <button onClick={handleAcceptSpecialDelivery} type="button" className="btn">
                {buildLocale(currentTranslate, 'zipCodeSpecialModalAcceptButton')}
              </button>
              <button onClick={() => setShowSpecialDeliveryModal(false)} type="button" className="btn btn-border">
                {buildLocale(currentTranslate, 'zipCodeSpecialModalRejectButton')}
              </button>
            </div>
          </div>
        </Modal>
      )}

      {showErrorModal && (
        <Modal
          isOpen={showErrorModal}
          onRequestClose={() => setShowErrorModal(false)}
          className={`modal_zip-code modal_zip-code_error`}
          overlayClassName="edit_overlay payment_overlay">
          <p className="zip-title">{buildLocale(currentTranslate, 'zipCodeErrorModalTitle')}</p>
          <p className="zip-subtitle">{buildLocale(currentTranslate, 'zipCodeErrorModalSubtitle')}</p>
          <button onClick={() => setShowErrorModal(false)} type="button" className="btn">
            {buildLocale(currentTranslate, 'zipCodeErrorModalButton')}
          </button>
        </Modal>
      )}
    </>
  );
};

export default ZipCodeModal;
